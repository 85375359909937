.event {
    color: #fff;
    padding: 20px;

    .event-selection {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.1);
        width: auto;
    
        label {
            font-size: 1.5rem;
            font-weight: 600;
            margin-right: 10px;
            margin-bottom: 0;
            width: auto;

            @media (max-width: 768px) {
                font-size: 1.2rem;
            }
        }
    
        select {
            padding: 5px 10px;
            font-size: 1.2rem;
            background-color: rgba(0,0,0,0.5);
            border-radius: 10px;
            border: none;
            color: white;
        }
    }

    .event-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        background-color: rgba(255, 255, 255, 0.1);
        padding: 10px;
        border-radius: 5px;

        .event-banner {
            width: 45%;
            height: 385px;
            object-fit: cover;
            border-radius: 5px;
        }

        .event-info {
            width: 55%;

            h2 {
                margin-bottom: 10px;
            }

            p {
                margin: 10px 0;
                line-height: 1.5rem;
            }

            .event-button {
                padding: 10px 20px;
                font-size: 1.2rem;
                border: none;
                border-radius: 5px;
                background-color: rgba(0,0,0,0.5);
                color: #fff;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: darkred;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .event-details {
            flex-direction: column;

            .event-banner {
                width: 100%;
            }

            .event-info {
                width: 100%;
            }
        }
    }
}
