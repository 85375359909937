body {
    background-color: #13377f;
}

.App {
    position: relative;
}

@media screen and (max-width: 768px) {
    .MuiSnackbarContent-message {
      flex: 1;
      text-align: center;
    }
  }