.banner {
    position: relative;
    width: 100%;
    color: white;
}

.banner-image {
    height: 90vh;
    background: url('../../../assets/Banner.jpg') top center / cover no-repeat;
}

.banner-info {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    max-width: 20%;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.banner h1, .banner p {
    line-height: 1.5rem;
}

.banner h1 {
    line-height: 2.5rem;
}

@media screen and (max-width: 768px) {
    .banner-image {
        height: 50vh;
    }
  
    .banner-info {
        position: static;
        align-self: center;
        transform: none;
        max-width: 80%;
        margin-top: 20px;
        text-align: center;
        margin: 20px auto;
    }
}