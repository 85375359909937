.series-section {
    color: white;
  }

  .title {
    text-align: center;
  }
  
  .series-cards {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

    @media screen and (max-width: 768px) {
          margin: 0;
          margin-top: 20px;
    }
  }