#faq {
    h1 {
        text-align: center;
        color: white;
        margin-bottom: 20px;
    }
    
    .faq-item {
        background-color: rgba(0,0,0,0.5);
        color: white;
        border-radius: 8px;
        width: 80%;
        margin: 10px auto;
        cursor: pointer;
        
        .question {
            padding: 15px;
        }
        
        .answer {
            background-color: rgba(255, 255, 255, 0.4);
            padding: 15px;
        }
    }
}

@media screen and (max-width: 768px) {
    h1 {
      padding-top: 0px;
    }
  }