.anime-section {
    color: white;
  }

  .title {
    text-align: center;
  }
  
  .anime-cards {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    flex-wrap: wrap;
  }