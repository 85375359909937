nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: background-color 0.5s ease;
}

.navbar.scrolled {
    background-color: #0d2659;
}

.back-button {
    color: white;
    cursor: pointer;
}

.sub-menu {
    display: none;
    position: absolute;
    top: 55px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    transition: background-color 0.5s ease;
    list-style-type: none;
    padding: 10px 0;
    min-width: 200px;
    z-index: 2;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px;
}

.show-sub-menu .sub-menu {
    display: block;
}

.sub-menu.scrolled {
    background-color: #0d2659;
}

.sub-menu li {
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.sub-menu li:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.logo {
    height: 50px;
}

nav ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
}

nav ul li {
    margin: 0 10px;
    position: relative;
}

nav a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

nav a:hover {
    color: #aaaaaa;
}

.menu-icon {
    display: none;
    background-color: transparent;
    border: none;
    transition: color 0.3s ease;
    margin-top: 0;
}

.menu-icon:hover {
    color: #aaaaaa;
}

@media screen and (max-width: 768px) {
    .logo {
        height: 40px;
    }

    .menu-icon {
        display: block;
        color: white;
        font-size: 1.5rem;
    }

    .menu {
        display: none;
        position: fixed; 
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.8);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100vh;
        margin: 0;
        padding: 0;
    }

    .menu.active {
        display: flex;
    }
    
    .menu li {
        margin: 20px 0;
    }

    .show-sub-menu .sub-menu {
        position: relative;
        top: initial;
        box-shadow: none;
        min-width: 100%;
        background-color: transparent;
    }

    .sub-menu li {
        padding: 15px 0;
    }

    .sub-menu li:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
}