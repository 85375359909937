.blog-section {
  width: 100%;
  color: white;

  .blog-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .title {
    color: white;
  }
  
  .blog-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 2rem;
    justify-content: center;
  }

  .blog-post {
    display: flex;
    flex-direction: column;
    width: 80%;
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
    background-color: rgba(255,255,255,0.1);
  
      .post-header {
        display: flex;
        align-items: center;
        gap: 1rem;
  
        .post-author-avatar {
          width: 150px;
          height: 150px;
          object-fit: cover;
          border-radius: 50%;
          margin-bottom: 1rem;
        }
  
        .author-info {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .post-author {
            font-weight: bold;
            margin-bottom: .5rem;
            font-size: 1.4rem;
          }
  
          .post-date {
            margin-bottom: 1rem;
            font-size: 1.2rem;
          }
        }
      }
  
      .post-title {
        font-size: 2rem;
        margin-bottom: 1rem;
        margin-top: 0;
      }
  
      .post-content {
        margin-bottom: 1rem;
      }
    }

    .blog-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

  
    .blog-button {
      display: block;
      margin: 2rem auto;
      padding: 1rem 2rem;
      background-color: rgba(0,0,0,0.5);
      border: none;
      border-radius: 5px;
      font-size: 1.2rem;
      transition: background-color .3s;
  
      &:hover {
        background-color: darkred;
      }
    }
  
    @media (max-width: 768px) {
      .blog-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
      }
  
      .blog-post {
        width: 80%;
        padding: 1rem;
      }
  
      .blog-post {
        .post-header {
          .post-author-avatar {
            width: 100px;
            height: 100px;
          }
        }
        
      }
    }
  }
  