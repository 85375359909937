.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .carousel-images {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .carousel-image {
    flex: 1 1 auto;
    margin: 0 10px;
    height: 35vh;
    width: 50vw;
    overflow: hidden;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 2200px) {
        width: 100%;
    }

    @media screen and (min-width: 2200px) {
        width: calc(100% / 6);
    }
}

.carousel-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 2200px) {
        width: auto;
    }

    @media screen and (min-width: 2200px) {
        width: auto;
    }
}

  .carousel-button {
    background-color: rgba(0,0,0,0.4);
    border-radius: 50%;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 10px;
    font-size: 3rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 5rem;

}

.carousel-button:hover {
    background-color: #ddd;
}

.carousel-button:active {
    background-color: #aaa;
}