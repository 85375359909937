.footer {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px 0;
    color: #fff;
    font-family: Arial, sans-serif;
    position: relative;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 15px;

        @media (min-width: 769px) {
            flex-direction: row;
        }
    }

    .footer__socials {
        display: flex;
        margin: 10px auto;
        margin-bottom: 20px;

        @media (min-width: 769px) {
            margin: 0;
        }

        .footer__social {
            color: #fff;
            margin-right: 15px;
            transition: color 0.3s ease;

            &:hover {
                color: darkred;
            }
        }
    }

    .footer__links {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        padding-left: 0;

        @media (min-width: 769px) {
            flex-direction: row;
            align-items: initial;
            margin-bottom: 0;
            margin-top: 0;
        }

        .footer__link {
            margin-right: 15px;
            padding: 10px;

            a {
                text-decoration: none;
                color: #fff;
                transition: color 0.3s ease;

                &:hover {
                    color: darkred;
                }
            }
        }
    }

    .footer__copy {
        text-align: center;
        font-size: 0.875rem;
    }
}
