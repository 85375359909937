.film-section {
    color: white;
  }

  .title {
    text-align: center;
  }
  
  .film-cards {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }