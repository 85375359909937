#pricing h1 {
    text-align: center;
    color: white;
    padding-top: 20px;
    margin-bottom: 20px;
}

.pricing-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-link {
    display: inline-block;
    background-color: #13377f; 
    color: #fff; 
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 4px; 
    transition: background-color 0.3s ease;
}

.button-link:hover {
    background-color: #c00; /* Darker button color on hover */
}

.pricing-info {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 8px;
    width: 500px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 30px;
    line-height: 1.5rem;
    text-align: justify;
}

.pricing-info-right {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 8px;
    width: 300px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 30px;
    line-height: 1.8rem;
    text-align: justify;
}

.pricing-info .paragraph {
    margin-bottom: 1rem;
}

.pricing-info p {
    margin-bottom: 1rem;
}

.pricing-card {
    background-color: rgba(0,0,0,0.5);
    color: white;
    border-radius: 8px;
    width: 300px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.card-title {
    text-align: center;
    margin-bottom: 1rem;
}

.card-title h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.card-title p {
    font-size: 1.2rem;
}

.card-content ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
}

.card-content ul li {
    margin-bottom: 0.5rem;
}

.card-content button {
    width: 100%;
    padding: 10px;
    background-color: #13377f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
}

.card-content button a {
    text-decoration: none;
    color: white;
}

.card-content button:hover {
    background-color: #BB0B0B;
}

