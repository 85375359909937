.mobile {
    padding: 20px;
    border-radius: 5px;
    color: #fff;
    
    .mobile-description {
        width: 60%;
        line-height: 1.5rem;
        display: flex;
        justify-content: center;
        text-align: center;
        background-color: rgba(255,255,255,0.1);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 2.2rem;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 30px;
        margin-top: 110px;
    }

    .card-mobile {
        padding: 20px;
        background-color: rgba(255,255,255,0.1);
        border-radius: 10px;

        button {
            margin-right: 10px;
            margin-top: 10px;
            font-size: 1.2rem;
        }

        .btn-back {
            padding: 10px 20px;
            font-size: 1.2rem;
            border: none;
            border-radius: 5px;
            background-color: rgba(0,0,0,0.5);
            color: #fff;
            margin-right: 10px;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darkred;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .img-mobile-2 {
            display: none;
        }
    

         .mobile-content {
            display: flex;
            justify-content: space-between;
            flex-direction: row reverse;

            .mobile-images {
                flex-basis: 40%;

                img {
                    width: 48%;
                    height: auto;
                    margin-bottom: 20px;
                    margin-right: 20px; 
                    border-radius: 5px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .mobile-text {
                flex-basis: 55%;

                .div {
                    margin-top: 50px;
                }

                p {
                    margin-bottom: 10px;
                }

                ol {
                    margin-left: 20px;

                    li {
                        line-height: 1.5rem;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .mobile .mobile-description {
        width: 80%;
    }

    .mobile .card-mobile .mobile-content .mobile-images {
        display: none;
    }

    .mobile .card-mobile .img-mobile-2 {
        display: block;
        margin-bottom: 10px;
        width: 100%;
        height: auto;
    }

    .mobile .card-mobile button {
        margin-bottom: 10px;
    }
    
    .mobile .card-mobile .mobile-content .mobile-text {
        flex-basis: 95% !important;
    }
}


