#contact {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 20px;
    color: white;
  }

  @media screen and (max-width: 768px) {
  #contact {
    margin: 20px;
    margin-top: 0px;
  }

  .title {
    padding-top: 0px;
    margin-top: 0px;
  }
}
  
  h1 {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .confirmation {
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  form, .explanation {
    background-color: rgba(0,0,0,0.3);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 30px;
    line-height: 1.5rem;
    text-align: justify;
    width: 100%;
    max-width: 400px;
  }
  
  .explanation {
    background-color: rgba(255,255,255,0.1);
  }
  
  label {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    color: white;
    font-weight: 600;
  }
  
  input[type='email'],
  textarea {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    font-size: 1em;
    line-height: 1.5em;
    color: black;
    resize: vertical;
  }

  textarea {
    padding-top: 2em;
    margin-bottom: 1em;
  }
  
  input[type='submit'] {
    width: 100%;
    padding: 10px;
    background-color: #13377f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    transition: background-color 0.3s;
  }
  
  input[type='submit']:hover {
    background-color: #BB0B0B;
  }