.categories-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  line-height: 1.5rem;
}

.categories-text {
  text-align: center;
  margin-bottom: 2rem;
  width: 60%;  
  max-width: 800px; 
  line-height: 2rem;
}

.categories-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
  max-width: 1600px;
}

.category {
  padding: 20px;
  text-align: center;
  color: white;
  background-color: rgba(0,0,0,0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.category h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.category p {
  line-height: 2rem;
}

.category p.count {
  font-weight: bold;
  color: rgba(255,255,255,0.7);
}

@media screen and (min-width: 768px) {
  .categories-container {
    grid-template-columns: repeat(4, 1fr);
  }
}