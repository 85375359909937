.testimonials-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    line-height: 1.5rem;
    margin-bottom: 2rem;
  
    h2 {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  
  .testimonials-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    width: 100%;
    max-width: 1600px;
  
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .testimonial {
    padding: 20px;
    text-align: center;
    color: white;
    background-color: rgba(0,0,0,0.5);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  
    p {
      line-height: 2rem;
    }
  }
  
  button {
    background-color: rgba(0,0,0,0.5);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 1.2rem;
    cursor: pointer;
  
    &:hover {
      background-color: darkred;
    }
  }
  