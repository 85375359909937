.about-page {
    padding: 20px;
    color: #fff;
    background-color: #0d2659;

    h1, h2 {
      color: #fff;
      margin-bottom: 10px;
    }

    .arg {
      margin: 10px 0;
      line-height: 1.5rem;
      text-align: center;
    }

    .intro, .timeline, .goals, .mission-vision, .disclaimer {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 10px;
      border-radius: 5px;
      text-align: center;
      margin-bottom: 20px;
      max-width: 800px;
    }

    @media (min-width: 768px) { 
      padding-top: 60px; 
      display: grid;
      grid-auto-flow: dense;
      gap: 20px;
      justify-content: center; 

      .intro {
        text-align: center;
      }

      .timeline, .goals, .mission-vision {
        text-align: center;
      }

      .disclaimer {
        grid-column: span 2;
        text-align: center;
      }
    }

    @media (max-width: 767px) { 
      padding-top: 60px; 
    }
}