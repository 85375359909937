.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 22%;
  height: 50vh;
  padding-bottom: 20px;
  margin: 20px;
  background-color: rgba(0,0,0,0.3);
  color: white;

  @media screen and (max-width: 768px) {
      width: 100%;
  }
}
  
  .card-icon {
    height: 60%;
    width: 100%;
  }

  .card-title, .card-argument {
    padding-right: 20px;
    padding-left: 20px;
  }

  .card-argument {
    padding-bottom: 20px;
    line-height: 1.5rem;
  }
  
 